import React from 'react';
import { 
  NavLink
} from "react-router-dom";

const Home = () => {
    return (
        <div>            
            <nav>
                    <div className="container-fluid">
                        <div className="row">            
                            <div className="col-6 mb-3 p-3">
                                <div className="card">
                                    <div className="card-body">
                                        <NavLink to="/dinamo-ianca-2021-09-04">
                                            Dinamo Bacau - Viitorul Ianca (1-2) 04-09-2021
                                        </NavLink>
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </div>
            </nav>
        </div>
    );
}

export default Home;
