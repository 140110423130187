import React from "react";
import { NavLink } from "react-router-dom";
import MetaTags from "react-meta-tags";

const cache = {};

function importAll(r) {
  r.keys().forEach((key) => (cache[key] = r(key)));
}
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(require.context("./media", false, /\.(png|jpe?g|svg)$/));

const images = Object.entries(cache).map((module) => module[1].default);

const DinamoBacauViiotrulIanca20210904 = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Dinamo Bacau - Viitorul Ianca</title>
        <meta
          name="description"
          content="Poze de la meciul de Liga a III-a Dinamo Bacau - Viitorul Ianca"
        />
        <meta property="og:title" content="Galerie foto" />
        <meta property="og:image" content={images[0]} />
      </MetaTags>
      <div className="container-fluid">
        <div className="row">
          <nav>
            <NavLink to="/">Home</NavLink>
          </nav>
          <h1>Dinamo Bacau - Viitorul Ianca (1-2) 04-09-2021</h1>

          {images.map((image) => (
            <div className="col-6 col-md-4 col-lg-2 mb-3">
              <div className="card">
                <div className="card-body">
                  <a href={image}>
                    <img className="w-100" src={image} alt="img" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DinamoBacauViiotrulIanca20210904;
