import './App.css';

import { Router, Route, Switch } from 'react-router'
import { createBrowserHistory } from 'history'

import DinamoBacauViiotrulIanca20210904 from './dinamo-bc/DinamoBacauViiotrulIanca20210904';
import Home from './Home/Home';

function App() {
  return (
    <div className="App">    
      <Router history={createBrowserHistory()} >
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/dinamo-ianca-2021-09-04">
            <DinamoBacauViiotrulIanca20210904 />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
